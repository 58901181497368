import { ChatMessage } from '../models/chat.model'

export const CONTENT_TAG_REGEX = /@(.+)@/
export const CONTENT_INDEX_REGEX = /#(\d+)/
export const SAVE_KEYWORD_REGEX = /\[(.+)\]/

export function extractIndices(messages: ChatMessage[]) {
    return messages
        .map((message) => {
            const match = message.content.match(CONTENT_INDEX_REGEX)

            if (match) {
                const contentIndex = Number.parseInt(match[1])
                return contentIndex > 0 ? contentIndex : null
            }
            return null
        })
        .filter((index) => index !== null)
}

export function extractedTags(messages: ChatMessage[]) {
    return messages
        .map((message) => {
            const match = message.content.match(CONTENT_TAG_REGEX)

            if (match) {
                const tag = match[1]
                return tag
            }
            return null
        })
        .filter((tag) => tag !== null)
}

export function getFirstMatchedTag(message: string) {
    const match = message.match(CONTENT_TAG_REGEX)
    return match ? match[1] : null
}

export function getFirstMatchedKeyword(message: string) {
    const match = message.match(SAVE_KEYWORD_REGEX)
    return match ? match[1] : null
}

export function getFirstMatchedIndex(message: string) {
    const match = message.match(CONTENT_INDEX_REGEX)
    return match ? Number.parseInt(match[1]) : null
}
