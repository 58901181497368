<div class="h-[calc(100vh-120px)] overflow-auto rounded-bl-lg rounded-tl-lg bg-base-100 shadow-md">
    <div class="border-r border-base-200">
        <div class="flex justify-between bg-secondary p-2 text-base-100">
            <h2 class="text-lg font-bold">Chats</h2>
            <i (click)="openAddGroupChatModal()" class="bx bx-plus cursor-pointer"></i>
        </div>
        <div class="h-full overflow-y-auto">
            <ul>
                @for (item of ['groupChatRooms', 'privateChatRooms']; track $index) {
                    <div class="divider divider-start">
                        <span class="pl-4">{{
                            item === 'groupChatRooms' ? 'Group Chat' : 'Private Chat'
                        }}</span>
                    </div>
                    @for (chatRoom of chatService[item](); track chatRoom) {
                        <li
                            (click)="selectChat(chatRoom)"
                            [class.bg-base-300]="chatService.currentChat()?.id === chatRoom?.id"
                            class="relative flex cursor-pointer items-center p-2 hover:bg-base-300"
                        >
                            <img
                                class="mr-2 h-8 w-8 rounded-full"
                                useFallbackImage
                                [src]="chatRoom | appChatImage"
                                alt="User Avatar"
                            />
                            <div class="user-info">
                                <p class="font-bold">
                                    {{ chatRoom | appChatName }}
                                </p>
                            </div>

                            @if (chatService.unreadMap()[chatRoom.id]; as unreadCount) {
                                <span
                                    class="badge badge-error badge-lg absolute right-2 top-[50%] translate-y-[-50%] text-xs text-white transition"
                                >
                                    {{ unreadCount }}
                                </span>
                            }
                        </li>
                    }
                }
            </ul>
        </div>
    </div>
</div>
