<main [formGroup]="form" class="grid w-full grid-cols-1 lg:w-[50vw]">
    <!-- Form -->
    <section class="mt-6 flex-1">
        @if (selectionControl.value !== 'OneOnOne') {
            <div class="flex flex-col">
                <tui-input
                    formControlName="name"
                    tuiTextfieldSize="l"
                    [tuiTextfieldCleaner]="true"
                    class="w-full"
                >
                    Name
                    <input tuiTextfield type="text" />
                </tui-input>
            </div>
        }

        <div class="mt-6 flex gap-4">
            <label class="flex gap-2">
                <tui-radio
                    [formControl]="selectionControl"
                    item="All"
                    size="l"
                    class="tui-space_bottom-3"
                ></tui-radio>
                All Members
            </label>

            <label class="flex gap-2">
                <tui-radio
                    [formControl]="selectionControl"
                    item="Selected"
                    size="l"
                    class="tui-space_bottom-3"
                ></tui-radio>
                Selected Members
            </label>

            @if (!isEdit) {
                <label class="flex gap-2">
                    <tui-radio
                        [formControl]="selectionControl"
                        item="OneOnOne"
                        size="l"
                        class="tui-space_bottom-3"
                    ></tui-radio>
                    One-on-One
                </label>
            }
        </div>

        @if (selectionControl.value === 'Selected') {
            <div class="custom-tag-color mt-4 flex flex-col">
                <tui-multi-select
                    *tuiLet="userListStateService.select('users') | async as users"
                    [tuiTextfieldLabelOutside]="true"
                    [formControl]="selectedUsersControl"
                    [identityMatcher]="identityMatcher"
                    [minlength]="2"
                    [stringify]="userStringify"
                    (searchChange)="onSearchChange($event)"
                    class="w-full"
                >
                    Add Members

                    <tui-data-list-wrapper
                        *tuiDataList
                        tuiMultiSelectGroup
                        [itemContent]="userStringify"
                        [items]="users | tuiHideSelected"
                    ></tui-data-list-wrapper>
                </tui-multi-select>
            </div>
        }

        @if (selectionControl.value === 'OneOnOne') {
            <div class="custom-tag-color mt-4 flex flex-col">
                <app-user-dropdown [control]="oneOnOneUserControl"></app-user-dropdown>
            </div>
        }
    </section>
</main>

<button (click)="saveChatRoom()" class="mt-4" tuiButton appearance="primary">
    <i class="bx bx-save mr-2"></i>
    Save
</button>
