import { Injectable, computed, inject, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { AlertService } from '@core/services/alert.service'
import { environment } from '@environment/environment'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { KeywordApiService } from '@modules/keyword/services/keyword-api.service'
import { tuiCeil } from '@taiga-ui/cdk'
import dayjs from 'dayjs'
import { objectify, sort, sum } from 'radash'
import { combineLatest, filter, switchMap, take, timer } from 'rxjs'
import io from 'socket.io-client'
import { ChatEvent, ChatMessage, ChatRoom } from '../models/chat.model'
import { ChatHighlightStateService } from '../states/chat-highlight-state.service'
import { getFirstMatchedKeyword } from '../utils/chat.util'
import { ChatApiService } from './chat-api.service'

const DEFAULT_SIZE = 50

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    private authStateService = inject(AuthStateService)
    private socket = io(`${environment.CHAT_URL}`, {
        withCredentials: true,
        auth: { token: this.authStateService.getState().accessToken },
    })
    private chatApiService = inject(ChatApiService)
    private keywordApiService = inject(KeywordApiService)
    private alertService = inject(AlertService)
    private chatHighlightStateService = inject(ChatHighlightStateService)
    private initialized = false

    groupChatRooms = signal<ChatRoom[]>([])
    privateChatRooms = signal<ChatRoom[]>([])
    currentChat = signal<ChatRoom | null>(null)

    //* query-states
    size = DEFAULT_SIZE
    page = signal<number>(1)
    totalPages = signal<number>(1)
    totalResults = signal<number>(0)

    // need to be in reverse order so that in frontend it will display correctly
    currentChatRecentMessages = signal<ChatMessage[]>([])
    currentPrivateChatParticipant = computed(
        () =>
            this.currentChat()?.participants.filter(
                (p) => p.id !== this.authStateService.getState().user.id,
            )[0],
    )
    whoIsTyping = signal<string>('')
    unreadMap = signal<{ [key: string]: number }>({})
    totalUnread = signal<number>(0)

    //* observables
    private currentChat$ = toObservable(this.currentChat)
    private currentPage$ = toObservable(this.page)

    init() {
        if (this.initialized) return
        this.reload()
        this.initialized = true
    }

    startedTyping() {
        this.socket.emit(ChatEvent.TYPING_EVENT, {
            chatId: this.currentChat().id,
            userFirstName: this.authStateService.getUser().firstName,
        })
    }

    stoppedTyping() {
        this.socket.emit(ChatEvent.STOP_TYPING_EVENT, {
            chatId: this.currentChat().id,
            userFirstName: this.authStateService.getUser().firstName,
        })
    }

    deleteGroupChat() {
        const currentChat = this.currentChat()
        if (!currentChat) return

        this.chatApiService
            .deleteGroupChat(currentChat.id)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.groupChatRooms.update((rooms) =>
                        rooms.filter((r) => r.id !== currentChat.id),
                    )
                    this.switchRoom(this.groupChatRooms()[0] || null)
                },
            })
    }

    leaveGroupChat() {
        const currentChat = this.currentChat()
        if (!currentChat) return

        this.chatApiService
            .leaveGroupChat(currentChat.id)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.groupChatRooms.update((rooms) =>
                        rooms.filter((r) => r.id !== currentChat.id),
                    )
                    this.switchRoom(this.groupChatRooms()[0] || null)
                },
                error: (err) => {
                    console.error('Failed to leave group chat:', err)
                },
            })
    }

    sendMessage(message: string) {
        // immediately update chat window with a fake message
        const sentMessage: ChatMessage = {
            chatId: this.currentChat().id,
            content: message,
            createdAt: new Date(),
            id: Math.random().toString(),
            senderId: this.authStateService.getUser().id,
            updatedAt: new Date(),
        }
        this.pushMessage(sentMessage)

        // update in the backend which will broadcast the message to all participants
        this.chatApiService
            .sendMessage(this.currentChat().id, message)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.markAsReadFrontend()
                },
            })
    }

    onMessageReceived(message: ChatMessage) {
        if (message.chatId === this.currentChat().id) {
            this.pushMessage(message)
        }
        this.updateUnreadCount(message.chatId)
    }

    markAsReadFrontend() {
        const currentChat = this.currentChat()
        if (!currentChat) return

        this.updateUnreadCount(currentChat.id, true)
    }

    updatePrivateChat(room: ChatRoom) {
        this.privateChatRooms.update((rooms) => rooms.map((r) => (r.id === room.id ? room : r)))
    }

    updateGroupChat(room: ChatRoom) {
        this.currentChat.update(() => room)
        this.groupChatRooms.update((rooms) => rooms.map((r) => (r.id === room.id ? room : r)))
    }

    pushRoom(room: ChatRoom) {
        if (room.isGroupChat) {
            this.groupChatRooms.update((rooms) => [...rooms, room])
        } else {
            this.privateChatRooms.update((rooms) => [...rooms, room])
        }
    }

    switchRoom(room: ChatRoom | null) {
        if (!room) return

        if (room.id !== this.currentChat()?.id) {
            this.chatHighlightStateService.setState({ contentIndices: [], searchTerms: [] })
            this.currentChatRecentMessages.set([])
            this.currentChat.set(room)
            this.chatHighlightStateService.setChatRoom(room)
        }

        this.markAsReadAfterOneSec()
    }

    private markAsReadBackend() {
        const currentChat = this.currentChat()
        if (!currentChat) return

        this.chatApiService.markAsRead(currentChat.id).pipe(take(1)).subscribe({})
    }

    private markAsReadAfterOneSec() {
        timer(1000)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.markAsReadFrontend()
                    this.markAsReadBackend()
                },
            })
    }

    private pushMessage(message: ChatMessage) {
        // we are showing the messages in FE using reverse-flex direction.
        // So, we need to add the new message at the beginning instead of usual appending
        this.currentChatRecentMessages.update((messages) => [message, ...messages])
        this.chatHighlightStateService.lookupContentIndex(message.content)
        this.chatHighlightStateService.lookupTag(message.content)
        const keyword = getFirstMatchedKeyword(message.content)

        keyword && this.saveKeyword(keyword)
    }

    private saveKeyword(keyword: string) {
        const mapKeyword = [{ numberOfWords: keyword.split(' ').length, suggestion: keyword }]

        this.keywordApiService.createMany(mapKeyword).subscribe({
            next: () => {
                this.alertService.success('Keyword saved inside Ai suggestions.')
            },
            error: () => {
                this.alertService.error('There was a problem saving keyword.')
            },
        })
    }

    private updateUnreadCount(chatId: string, reset = false) {
        this.unreadMap.update((unreadMap) => {
            unreadMap[chatId] = reset ? 0 : (unreadMap[chatId] || 0) + 1
            return unreadMap
        })
        this.totalUnread.set(sum(Object.values(this.unreadMap())))
    }

    private getRoomById(id: string) {
        return [...this.groupChatRooms(), ...this.privateChatRooms()].find((r) => r.id === id)
    }

    private reload() {
        this.registerSocketEvents()
        this.loadMyChats()
        this.loadUnreadCounts()
        this.continueLoadingCurrentChatMessages()
        this.continueLoadingUnreadCounts()
    }

    private joinChatRoom(room: ChatRoom) {
        this.socket.emit(ChatEvent.JOIN_CHAT_EVENT, room.id)
    }

    private registerSocketEvents() {
        this.socket.on('connect', () => {
            console.log(ChatEvent.CONNECTED_EVENT)
        })

        this.socket.on(ChatEvent.NEW_CHAT_EVENT, (newRoom: ChatRoom) => {
            if (!this.currentChat()?.id) {
                this.currentChat.set(newRoom)
            }

            this.pushRoom(newRoom)
        })

        this.socket.on(ChatEvent.MESSAGE_RECEIVED_EVENT, (message: ChatMessage) => {
            this.onMessageReceived(message)
        })

        this.socket.on(ChatEvent.TYPING_EVENT, ({ chatId, userFirstName }) => {
            chatId === this.currentChat()?.id
                ? this.whoIsTyping.set(this.currentChat().isGroupChat ? 'Someone' : userFirstName)
                : this.whoIsTyping.set('')
        })

        this.socket.on(ChatEvent.STOP_TYPING_EVENT, ({ chatId, userFirstName }) => {
            this.whoIsTyping.set('')
        })
    }

    private loadMyChats() {
        this.chatApiService
            .getMyChats()
            .pipe(take(1))
            .subscribe({
                next: ({ data }) => {
                    const groupChats = data.filter((m) => m.isGroupChat)
                    const privateChats = data.filter((m) => !m.isGroupChat)
                    this.groupChatRooms.set(groupChats)
                    this.privateChatRooms.set(privateChats)
                    const room = data?.[0] || null
                    this.switchRoom(room)
                },
            })
    }

    private loadUnreadCounts() {
        this.chatApiService
            .getMyChatsUnreadCount()
            .pipe(take(1))
            .subscribe({
                next: ({ data }) => {
                    this.unreadMap.set(
                        objectify(
                            data,
                            (c) => c.chatRoomId,
                            (c) => c.unreadCount,
                        ),
                    )
                },
            })
    }

    private continueLoadingUnreadCounts() {
        // start after 10 sec, then every 2 min
        timer(10_000, 120_000)
            .pipe(
                switchMap(() => {
                    return this.chatApiService.getMyChatsUnreadCount()
                }),
            )
            .subscribe({
                next: ({ data }) => {
                    this.totalUnread.set(sum(data, (f) => f.unreadCount))
                },
            })
    }

    private continueLoadingCurrentChatMessages() {
        combineLatest([this.currentChat$, this.currentPage$])
            .pipe(
                filter(([currentChat, currentPage]) => !!currentChat && !!currentPage),
                switchMap(([currentChat, currentPage]) => {
                    if (currentPage === 1) this.joinChatRoom(currentChat)

                    return this.chatApiService.getMessages(currentChat.id, currentPage, this.size)
                }),
            )
            .subscribe({
                next: ({ data: messages, meta }) => {
                    this.currentChatRecentMessages.set([
                        ...this.currentChatRecentMessages(),
                        ...this.sortReverse(messages),
                    ])
                    this.totalPages.update(() => tuiCeil(meta.total / this.size))
                    this.totalResults.update(() => meta.total)
                },
            })
    }

    private sortReverse(messages: ChatMessage[]) {
        return sort(messages, (f) => dayjs(f.createdAt).valueOf(), true)
    }
}
