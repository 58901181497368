import { Component, EventEmitter, Input, Output, signal } from '@angular/core'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'

@Component({
    selector: 'app-suggestions',
    standalone: true,
    imports: [...TaigaUiImports],
    templateUrl: './suggestions.component.html',
    styleUrl: './suggestions.component.scss',
})
export class SuggestionsComponent {
    @Input({ required: true }) dropdownOpen: boolean
    @Input({ required: true }) suggestions = signal<string[] | null>(null)
    @Output() suggestionSelected = new EventEmitter<string>()

    getSuggestion(suggestion: string) {
        this.suggestionSelected.emit(suggestion)
    }
}
