<div
    *tuiLet="chatService.currentChat(); let currentChat"
    class="m-0 flex h-[calc(100vh-120px)] flex-1 flex-col overflow-auto rounded-br-lg rounded-tr-lg bg-base-100 shadow-md"
>
    @if (currentChat) {
        <div class="flex items-center justify-between bg-secondary p-2 text-base-100">
            <div class="flex items-center gap-4">
                <img
                    class="mr-2 h-6 w-6 rounded-full"
                    [src]="currentChat | appChatImage"
                    alt="User Avatar"
                    (click)="openGroupChatEditModal()"
                />
                <h1
                    class="text-lg"
                    (click)="openGroupChatEditModal()"
                    [class.cursor-pointer]="currentChat?.isGroupChat"
                >
                    {{ currentChat | appChatName }}
                </h1>
            </div>

            <div class="flex items-center gap-4">
                @if (currentChat?.isGroupChat) {
                    <i class="bx bx-trash cursor-pointer" (click)="deleteChat()"></i>
                }
                @if (currentChat?.isGroupChat) {
                    <i class="bx bx-edit cursor-pointer" (click)="openGroupChatEditModal()"></i>
                }
                @if (currentChat?.isGroupChat) {
                    <i class="bx bx-exit cursor-pointer" (click)="leaveChatGroup()"></i>
                }
                <ng-content></ng-content>
            </div>
        </div>

        <div
            infiniteScroll
            [infiniteScrollUpDistance]="0"
            [infiniteScrollThrottle]="200"
            [scrollWindow]="false"
            (scrolledUp)="onScroll()"
            #scrollToBottomContainer
            class="grow overflow-y-auto"
        >
            <div class="inline-flex w-full flex-1 flex-col-reverse p-4">
                @for (message of chatService.currentChatRecentMessages(); track message) {
                    <div class="mb-2">
                        @if (message.senderId !== authStateService.getUserId()) {
                            <div class="grid justify-start">
                                <div class="flex items-center">
                                    <img
                                        class="mr-2 h-6 w-6 rounded-full"
                                        [src]="
                                            chatService.currentPrivateChatParticipant()
                                                ?.profileImage
                                        "
                                        useFallbackImage
                                        alt="User Avatar"
                                    />
                                    <div class="max-w-xs rounded-lg bg-base-300 px-5 py-2">
                                        <p>{{ message.content }}</p>
                                    </div>
                                </div>
                                <span class="ml-12 block text-left text-[.6rem] opacity-50">{{
                                    message.createdAt | date: 'MMMM d, y h:mm a'
                                }}</span>
                            </div>
                        }
                        @if (message.senderId === authStateService.getUserId()) {
                            <div class="grid justify-end">
                                <div class="flex items-center justify-end">
                                    <div
                                        class="max-w-xs rounded-lg bg-primary px-5 py-2 text-white"
                                    >
                                        <p>{{ message.content }}</p>
                                    </div>
                                    <img
                                        class="ml-2 h-6 w-6 rounded-full"
                                        [src]="authStateService.getUser().profileImage"
                                        useFallbackImage
                                        alt="User Avatar"
                                    />
                                </div>
                                <span class="mr-12 block text-right text-[.6rem] opacity-50">{{
                                    message.createdAt | date: 'MMMM d, y h:mm a'
                                }}</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>

        <div class="justify-items-end bg-base-100 p-4">
            <div
                *tuiLet="chatService.whoIsTyping() as someone"
                [style.visibility]="someone !== '' ? 'visible' : 'hidden'"
                class="mb-1 text-xs italic opacity-50"
            >
                {{ someone }} is typing...
            </div>

            <app-suggestions
                [suggestions]="contentsName"
                [dropdownOpen]="dropdownOpen"
                (suggestionSelected)="onSuggestionSelected($event)"
                class="ml-8"
            ></app-suggestions>

            <div class="flex">
                <span
                    class="loading loading-spinner loading-sm mr-2"
                    [ngClass]="{ visible: loading(), invisible: !loading() }"
                ></span>
                <input
                    [formControl]="newMessage"
                    (keyup.enter)="sendMessage()"
                    type="text"
                    placeholder="Type a message..."
                    class="flex-1 rounded-bl-lg rounded-tl-lg border border-gray-300 p-2 focus:border-secondary focus:outline-none"
                />
                <button
                    (click)="sendMessage()"
                    class="btn btn-secondary w-28 rounded-bl-none rounded-br-lg rounded-tl-none rounded-tr-lg p-2 text-white"
                >
                    Send
                </button>
            </div>
        </div>
    } @else {
        <div class="flex h-full items-center justify-center">
            <p class="text-lg text-gray-500">Please select someone to chat</p>
        </div>
    }
</div>
