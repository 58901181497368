<tui-hosted-dropdown
    #dropdown
    [content]="show_suggestions"
    [(open)]="dropdownOpen"
    [tuiDropdownMaxHeight]="500"
>
</tui-hosted-dropdown>

<ng-template #show_suggestions>
    <tui-data-list size="l" class="list">
        <tui-opt-group>
            @for (suggestion of suggestions(); track suggestion) {
                <button tuiOption (click)="getSuggestion(suggestion)">
                    {{ suggestion }}
                </button>
            }
        </tui-opt-group>
    </tui-data-list>
</ng-template>
