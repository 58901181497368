import { User } from '@modules/user/model/user.interface'

export interface ChatMessage {
    id?: string
    senderId: string
    content: string
    chatId: string
    createdAt: Date
    updatedAt: Date
}

export type ChatRoom = {
    id?: string
    name: string
    isGroupChat: boolean
    participants: User[]
    unreadCount?: number
    createdAt: Date
    updatedAt: Date
}

export type ChatUnreadResponse = { chatRoomId: string; unreadCount: number }

export const ChatEvent = Object.freeze({
    // ? once user is ready to go
    CONNECTED_EVENT: 'connected',
    // ? when user gets disconnected
    DISCONNECT_EVENT: 'disconnect',
    // ? when user joins a socket room
    JOIN_CHAT_EVENT: 'joinChat',
    // ? when participant gets removed from group, chat gets deleted or leaves a group
    LEAVE_CHAT_EVENT: 'leaveChat',
    // ? when admin updates a group name
    UPDATE_GROUP_NAME_EVENT: 'updateGroupName',
    // ? when new message is received
    MESSAGE_RECEIVED_EVENT: 'messageReceived',
    // ? when there is new one on one chat, new group chat or user gets added in the group
    NEW_CHAT_EVENT: 'newChat',
    // ? when there is an error in socket
    SOCKET_ERROR_EVENT: 'socketError',
    // ? when participant stops typing
    STOP_TYPING_EVENT: 'stopTyping',
    // ? when participant starts typing
    TYPING_EVENT: 'typing',
    // ? when message is deleted
    MESSAGE_DELETE_EVENT: 'messageDeleted',
})

export const AvailableChatEvents = Object.values(ChatEvent)
