import { Component, Injector, inject } from '@angular/core'
import { UseFallbackImageDirective } from '@core/directives/use-fallback-image.directive'
import { ChatRoom } from '@modules/chat/models/chat.model'
import { ChatService } from '@modules/chat/services/chat.service'
import { TuiDialogService } from '@taiga-ui/core'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { ChatImagePipe } from '../../pipes/chat-image.pipe'
import { ChatNamePipe } from '../../pipes/chat-name.pipe'
import { AddChatDialogComponent } from '../add-chat-dialog/add-chat-dialog.component'

@Component({
    selector: 'app-chat-list',
    standalone: true,
    templateUrl: './chat-list.component.html',
    styleUrl: './chat-list.component.scss',
    imports: [ChatNamePipe, ChatImagePipe, UseFallbackImageDirective],
})
export class ChatListComponent {
    private readonly dialogs = inject(TuiDialogService)
    private readonly injector = inject(Injector)
    chatService = inject(ChatService)

    openAddGroupChatModal() {
        this.dialogs
            .open<ChatRoom>(new PolymorpheusComponent(AddChatDialogComponent, this.injector), {
                size: 'auto',
                data: null,
                dismissible: true,
                label: 'Create Chat',
            })
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.chatService.pushRoom(result)
                        this.chatService.switchRoom(result)
                    }
                },
            })
    }

    selectChat(chatRoom: ChatRoom) {
        this.chatService.switchRoom(chatRoom)
    }
}
