import { Injectable, inject, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { SimpleStore } from '@core/states/simple-store'
import { Content } from '@modules/content/models/content.interface'
import { ContentApiService } from '@modules/content/services/content-api.service'
import { unique } from 'radash'
import { Subject, debounceTime, filter, of, switchMap, take, takeUntil, tap } from 'rxjs'
import { ChatMessage, ChatRoom } from '../models/chat.model'
import {
    extractIndices,
    extractedTags,
    getFirstMatchedIndex,
    getFirstMatchedTag,
} from '../utils/chat.util'

export interface ChatHighlightState {
    contentIndices: number[]
    indexContents: { for: number; result: Content }[]
    searchTerms: string[]
    searchTermsContents: { for: string; result: Content[] }[]
    indexLoading: boolean
    tagLoading: boolean
    selectedChatRoom: ChatRoom | null
}

const initialState: ChatHighlightState = {
    contentIndices: [],
    indexContents: [],
    searchTerms: [],
    searchTermsContents: [],
    indexLoading: false,
    tagLoading: false,
    selectedChatRoom: null,
}

@Injectable({ providedIn: 'root' })
export class ChatHighlightStateService extends SimpleStore<ChatHighlightState> {
    private contentApiService = inject(ContentApiService)

    latestContentIndexFound = signal<number>(0)
    latestSearchTermFound = signal<string>('')

    private unsubscribe$ = new Subject<void>()
    private latestContentIndexFound$ = toObservable(this.latestContentIndexFound)
    private latestSearchTermFound$ = toObservable(this.latestSearchTermFound)

    constructor() {
        super(initialState)
        this.init()
    }

    init() {
        this.continueLoadingLatestContentIndex()
        this.continueLoadingLatestSearchTermContents()
        this.loadContentsByIndices()
        this.loadContentsBySearchTerms()
    }

    ngOnDestroy() {
        this.unsubscribe$.next()
        this.unsubscribe$.complete()
    }

    lookupContentIndex(message: string) {
        const contentIndex = getFirstMatchedIndex(message)
        if (contentIndex > 0) {
            this.latestContentIndexFound.set(contentIndex)
        }
    }

    lookupTag(message: string) {
        const tag = getFirstMatchedTag(message)
        if (tag) {
            this.latestSearchTermFound.set(tag)
        }
    }

    setContentIndices(messages: ChatMessage[]) {
        this.setState({
            contentIndices: extractIndices(messages),
        })
    }

    setTagContents(messages: ChatMessage[]) {
        this.setState({ searchTerms: extractedTags(messages) })
    }

    setChatRoom(chatRoom: ChatRoom) {
        this.setState({ selectedChatRoom: chatRoom })
        this.loadContentsByIndices()
        this.loadContentsBySearchTerms()
    }

    private continueLoadingLatestContentIndex() {
        this.latestContentIndexFound$
            .pipe(
                filter((index: number) => index > 0),
                debounceTime(500),
                switchMap((index: number) => {
                    return this.contentApiService.findByIndices([index])
                }),
            )
            .subscribe({
                next: ({ data }) => {
                    this.setState({ indexContents: [...data, ...this.getState().indexContents] })
                },
                error: (err) => {
                    console.error('Error loading indexContents', err)
                },
            })
    }

    private continueLoadingLatestSearchTermContents() {
        this.latestSearchTermFound$
            .pipe(
                filter((tag: string) => tag !== ''),
                debounceTime(500),
                switchMap((tag: string) => {
                    return this.contentApiService.findBySearchTerms([tag])
                }),
            )
            .subscribe({
                next: ({ data }) => {
                    this.setState({
                        searchTermsContents: [...data, ...this.getState().searchTermsContents],
                    })
                },
                error: (err) => {
                    console.error('Error loading tagContents', err)
                },
            })
    }

    private loadContentsByIndices() {
        this.select('selectedChatRoom')
            .pipe(
                switchMap((chatRoom) => {
                    if (!chatRoom) return of([])
                    return this.select('contentIndices')
                }),
                tap(() => this.setState({ indexContents: [] })),
                filter((contentIndices) => contentIndices.length > 0),
                tap(() => this.setState({ indexLoading: true })),
                take(1),
                debounceTime(500),
                switchMap((contentIndices) =>
                    this.contentApiService.findByIndices(unique(contentIndices, (f) => f)),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: ({ data }) => {
                    this.setState({ indexContents: data, indexLoading: false })
                },
                error: (err) => {
                    console.error('Error loading indexContents', err)
                    this.setState({ indexLoading: false })
                },
                complete: () => {
                    this.setState({ indexLoading: false })
                },
            })
    }

    private loadContentsBySearchTerms() {
        this.select('selectedChatRoom')
            .pipe(
                switchMap((chatRoom) => {
                    if (!chatRoom) return of([])
                    return this.select('searchTerms')
                }),
                tap(() => this.setState({ searchTermsContents: [] })),
                filter((searchTerms) => searchTerms.length > 0),
                tap(() => this.setState({ tagLoading: true })),
                take(1),
                debounceTime(500),
                switchMap((searchTerms) =>
                    this.contentApiService.findBySearchTerms(unique(searchTerms, (f) => f)),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe({
                next: ({ data }) => {
                    this.setState({ searchTermsContents: data, tagLoading: false })
                },
                error: (err) => {
                    console.error('Error loading indexContents', err)
                    this.setState({ indexLoading: false })
                },
                complete: () => {
                    this.setState({ indexLoading: false })
                },
            })
    }
}
