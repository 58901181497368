import { Pipe, type PipeTransform, inject } from '@angular/core'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '../models/chat.model'

@Pipe({
    name: 'appChatImage',
    standalone: true,
})
export class ChatImagePipe implements PipeTransform {
    private authStateService = inject(AuthStateService)

    transform(value?: ChatRoom): string {
        if (!value) return ''

        const otherUser = value.participants.find(
            (participant) => participant.id !== this.authStateService.getUserId(),
        )

        return value.isGroupChat
            ? './../../../../assets/images/user-group.png'
            : (otherUser?.profileImage ?? './../../../../assets/images/default.png')
    }
}
